import React from 'react'
import doomtrooperImage from '../doomtrooper.jpg'
import dontgooutImage from '../dontgoout.jpg'
import { Icon, Container, Grid, Image, List } from 'semantic-ui-react'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <Grid doubling columns={2} style={{ padding: '15px' }}>
      <Grid.Column width={'50%'}>
        <Image
          style={{ cursor: 'pointer' }}
          src={doomtrooperImage}
          onClick={() => window.open('https://doomtroopergame.com')}
          fluid
        />
      </Grid.Column>
      <Grid.Column width={'50%'}>
        <Image
          style={{ cursor: 'pointer' }}
          src={dontgooutImage}
          onClick={() =>
            window.open(
              'https://store.steampowered.com/app/1296510/Dread_X_Collection/'
            )
          }
          fluid
        />
      </Grid.Column>
    </Grid>

    <div style={{ padding: '30px' }}>
      <p>
        Secret Cow Level is a game development studio based in Los Angeles
        California founded by{' '}
        <a href="https://twitter.com/framerate">Justin Reynard</a> (Fallout: New
        Vegas, Alpha Protocol, Dungeon Siege III) and{' '}
        <a href="https://twitter.com/ModemSymphony93">Stephen Berry</a>.
      </p>
      <p>
        We're currently developing an in-house engine to power the digital
        reimagining of the DOOMTROOPER Collectible Card Game set for release in
        2020 with our partners, Heroic Signatures.
      </p>
      <p>
        More info on DOOMTROOPER available below or at our development blog.
      </p>
      <p>
        If you're interested in more information on our CCG engine, please email{' '}
        <a href="mailto:info@secretcowlevel.com">info@secretcowlevel.com</a>.
      </p>
    </div>
    <Container textAlign={'center'}>
      <List horizontal style={{ cursor: 'pointer' }}>
        <List.Item>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://twitter.com/secretcowlevel"
          >
            <Icon name="twitter" />
          </a>
        </List.Item>
        <List.Item>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://facebook.com/secretcowlevel"
          >
            <Icon name="facebook" />
          </a>
        </List.Item>
        <List.Item>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://twitch.tv/justinreynard"
          >
            <Icon name="twitch" />
          </a>
        </List.Item>
        <List.Item>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://github.com/secretcowlevel"
          >
            {' '}
            <Icon name="github" />
          </a>
        </List.Item>
      </List>
    </Container>
  </Layout>
)

export default IndexPage
